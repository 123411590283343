import { get } from '@/services/axios/axios'
import { post } from '@/services/axios/axios'
import { ServicesTypes } from './axios/axios.interface'

interface checkEmailProps {
  email: string
  handleSignIn?: (data) => void
  handleSignUp?: (data) => void
}

interface signUpTuuri {
  firstName: string
  lastName: string
  email: string
  password: string
  phone: string
  country: string
  city: string
}

export const checkEmail = async ({
  email,
  handleSignIn = () => {},
  handleSignUp = () => {},
}: checkEmailProps) => {
  try {
    await get({ path: `check-email/${email}` })
    handleSignIn({ email: email })
    return true
  } catch (e) {
    handleSignUp({ email: email })
    return false
  }
}

export const recoverPassword = async (email) => {
  await get({ path: `recovery-password/${email}` })
}

export const signUpTuuri = async (
  data: signUpTuuri,
  setModalTitle: (string) => void,
  setModalBody: (string) => void
) => {
  const email = data.email
  await post({
    path: 'auth/tuuri/register',
    data: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
      phone: data.phone,
      country: data.country,
      city: data.city,
    },
    type: ServicesTypes.newApi,
  })
    .then((data: { accessToken: string; refreshToken: string }) => {
      if (!!data.accessToken) {
        setModalTitle('tuuriRegister.modalTitle.success')
        setModalBody('tuuriRegister.modalBody.success')
        get({ path: `send-email-tuuri/${email}`, headers: {} })
        return true
      }
      return false
    })
    .catch((e) => {
      console.log(e)
      if (e?.data?.error?.details === 'Conflict') {
        setModalTitle('tuuriRegister.modalTitle.conflictError')
        setModalBody('tuuriRegister.modalBody.conflictError')
        return false
      }
      setModalTitle('tuuriRegister.modalTitle.genericError')
      setModalBody('tuuriRegister.modalBody.genericError')
      return false
    })
}
