import React, { useState } from 'react'
import blueGTLogo from '@/assets/svgs/logo-mobile-blue.svg'
import { emailPatternRegex, namePatternRegex } from '@/constants/regex'
import { Control, Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneCustomInput from '@/components/PhoneCustomInput/PhoneCustomInput'
import { CountryRegionSelect } from '@/components/CountryRegionSelect'
import { FormErrorMessage } from '@/components/FormErrorMessage'
import { signUpTuuri } from '@/services/signInUpServices'
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  useDisclosure,
  Checkbox,
} from '@nextui-org/react'

const TuuriRegisterPage = () => {
  const { t } = useTranslation()

  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalBody, setModalBody] = useState('')

  const inputStyles = {
    base: ['h-14'],
    input: ['text-base', 'placeholder:text-lightGray', 'px-3'],
    inputWrapper: [
      'bg-mediumGray',
      'shadow-none',
      'h-12',
      'rounded-2xl',
      'last-child:mb-2',
    ],
  }

  const errorStyles = 'text-red-400 text-xs md:text-small font-light '

  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<{
    firstName: string
    lastName: string
    email: string
    password: string
    phone: string
    country: string
    city: string
    terms: string
  }>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
  })
  watch()

  const onSubmit: SubmitHandler<any> = async (data) => {
    setIsLoading(true)
    await signUpTuuri(data, setModalTitle, setModalBody)
    onOpen()
    setIsLoading(false)
  }

  return (
    <section className="relative md:bg-black flex flex-col h-auto pb-12 md:pb-48">
      <div className='hidden md:block h-[900px] absolute top-0 z-0 scale-x-[-1] blur-sm w-full bg-cover -mt-16 bg-[url("https://firebasestorage.googleapis.com/v0/b/winter-rider-365419.appspot.com/o/data_web%2Fimages%2FGettyImages-1476784894.webp?alt=media&token=9342869d-f21e-4950-897f-16f839ded7c4")]'></div>
      <div className="gradient-overlay2 hidden md:block -mt-16 h-[900px]"></div>

      <div className="z-10">
        <div className="layout-padding h-full md:h-auto flex flex-col md:flex-row justify-between w-full gap-0 md:gap-8 sm:mt-4 tall:mt-[6%] short:!mt-[5%] mb-12 text-black md:text-white">
          <div className="w-full md:w-2/4">
            <h2 className="text-2xl md:text-4xl">{t('tuuriRegister.title')}</h2>
            <p className="text-lg md:text-2xl mt-4">
              {t('tuuriRegister.benefitsForYou')}
            </p>
            <p className="text-sm md:text-lg mt-4">
              <span className="font-bold">
                {t('tuuriRegister.benefitTitle1')}
              </span>
              {t('tuuriRegister.benefit1')}
            </p>
            <p className="text-sm md:text-lg mt-4">
              <span className="font-bold">
                {t('tuuriRegister.benefitTitle2')}
              </span>
              {t('tuuriRegister.benefit2')}
            </p>
            <p className="text-sm md:text-lg mt-4">
              <span className="font-bold">
                {t('tuuriRegister.benefitTitle3')}
              </span>
              {t('tuuriRegister.benefit3')}
            </p>
          </div>
          <div className="w-full md:w-2/4 !bg-white flex flex-col -mt-6 gap-4 text-black rounded-lg h-auto p-0 md:p-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="hidden md:flex justify-between items-center w-full">
                <h2 className="text-2xl">
                  {t('tuuriRegister.registerAsTuuri')}
                </h2>
                <img
                  src={blueGTLogo}
                  alt="blue gotuuri logo"
                  className="w-12 h-10"
                />
              </div>
              <div className="flex flex-col gap-4 items-center">
                <div className="flex gap-4 mt-4 justify-between items-center w-full">
                  <div className="flex flex-col w-full">
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: t('signUpForm.errors.required'),
                        pattern: {
                          message: t('signUpForm.errors.invalidFirstName'),
                          value: namePatternRegex,
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          value={value}
                          onChange={onChange}
                          classNames={inputStyles}
                          placeholder={t('bookingDetailsPage.firstName')}
                          errorMessage={errors[`firstName`]?.message}
                          isInvalid={!!errors[`firstName`]?.message}
                        />
                      )}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: t('signUpForm.errors.required'),
                        pattern: {
                          message: t('signUpForm.errors.invalidLastName'),
                          value: namePatternRegex,
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          value={value}
                          onChange={onChange}
                          classNames={inputStyles}
                          placeholder={t('bookingDetailsPage.lastName')}
                          errorMessage={errors[`lastName`]?.message}
                          isInvalid={!!errors[`lastName`]?.message}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="flex w-full">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: t('signUpForm.errors.required'),
                      pattern: {
                        message: t('signUpForm.errors.invalidEmail'),
                        value: emailPatternRegex,
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        type="email"
                        classNames={inputStyles}
                        placeholder={t('bookingDetailsPage.email')}
                        errorMessage={errors[`email`]?.message}
                        isInvalid={!!errors[`email`]?.message}
                      />
                    )}
                  />
                </div>
                <div className="flex w-full">
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: t('signUpForm.errors.required'),
                      pattern: {
                        message: t('signInForm.errors.invalidPassword'),
                        value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^<>\"'&;]{8,}$/,
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        type="password"
                        classNames={inputStyles}
                        placeholder={t('tuuriRegister.createPassword')}
                        errorMessage={errors[`password`]?.message}
                        isInvalid={!!errors[`password`]?.message}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col w-full !h-12 mb-2">
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: t('signUpForm.errors.required'),
                      minLength: {
                        value: 7,
                        message: t('signUpForm.errors.invalidPhone'),
                      },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <PhoneCustomInput
                        value={value}
                        isEditing={true}
                        onChange={onChange}
                        height="h-12"
                      />
                    )}
                  />
                  <p className={errorStyles}>
                    {errors[`phone`]?.message.toString()}
                  </p>
                </div>
                <div className="w-full mb-2">
                  <CountryRegionSelect
                    clearErrors={clearErrors}
                    setValue={setValue}
                    register={register}
                    height="h-12"
                  />
                  <div className="flex gap-4 justify-between">
                    <div className="w-2/4">
                      <FormErrorMessage
                        forField={'country'}
                        control={control as Control<unknown>}
                      />
                    </div>
                    <div className="w-2/4">
                      <FormErrorMessage
                        forField={'city'}
                        control={control as Control<unknown>}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-start justify-start w-full md:w-4/6 text-center ">
                  <Controller
                    name="terms"
                    control={control}
                    rules={{
                      required: t('signUpForm.errors.required'),
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        value={value}
                        onValueChange={onChange}
                        className="!-mr-2 -mt-2"
                      />
                    )}
                  />
                  <div
                    className="text-center w-fit !text-sm md:text-base p-0 -mx-1 [&>a]:text-gotuuriCyan [&>a]:no-underline"
                    dangerouslySetInnerHTML={{
                      __html: t('signUpForm.disclosureFirstPart'),
                    }}
                  ></div>
                </div>
                <div className="w-full flex justify-center">
                  <Button
                    isLoading={isLoading}
                    isDisabled={!isValid || (isValid && !getValues()?.city)}
                    type="submit"
                    className="w-2/4 !bg-black !text-white"
                  >
                    {t('tuuriRegister.submitButton')}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        className="px-6 py-4 text-center"
        size="md"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 text-lg md:text-xl">
                {t(modalTitle)}
              </ModalHeader>
              <ModalBody className="text-sm md:text-base">
                <p>{t(modalBody)}</p>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </section>
  )
}

export default TuuriRegisterPage
